/* Use these when you need to hide an element. Can you used to overcome  */

.visibility-hidden   {
    visibility: hidden;
}
.visibility-visible {
    visibility: visible;
}

@media (--breakpoint-not-small) {
    .visibility-hidden-ns {
        visibility: hidden;
    }
    .visibility-visible-ns {
        visibility: visible;
    }
    
}

@media (--breakpoint-medium) {
    .visibility-hidden-m {
        visibility: hidden;
    }
    .visibility-visible-m {
        visibility: visible;
    }
    
}

@media (--breakpoint-large) {
    .visibility-hidden-l {
        visibility: hidden;
    }
    .visibility-visible-l {
        visibility: visible;
    }
    
}
