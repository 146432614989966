/*! TACHYONS v4.9.0 !*/
/* External Library Includes */
@import '../../node_modules/tachyons-custom/src/_normalize.css';

/* Modules */
@import '../../node_modules/tachyons-custom/src/_box-sizing.css';
@import '../../node_modules/tachyons-custom/src/_aspect-ratios.css';
@import '../../node_modules/tachyons-custom/src/_images.css';
@import '../../node_modules/tachyons-custom/src/_background-size.css';
@import '../../node_modules/tachyons-custom/src/_background-position.css';
@import '../../node_modules/tachyons-custom/src/_outlines.css';
@import '../../node_modules/tachyons-custom/src/_borders.css';
@import '../../node_modules/tachyons-custom/src/_border-colors.css';
@import '../../node_modules/tachyons-custom/src/_border-radius.css';
@import '../../node_modules/tachyons-custom/src/_border-style.css';
@import '../../node_modules/tachyons-custom/src/_border-widths.css';
@import '../../node_modules/tachyons-custom/src/_box-shadow.css';
@import '../../node_modules/tachyons-custom/src/_code.css';
@import '../../node_modules/tachyons-custom/src/_coordinates.css';
@import '../../node_modules/tachyons-custom/src/_clears.css';
@import '../../node_modules/tachyons-custom/src/_display.css';
@import '../../node_modules/tachyons-custom/src/_flexbox.css';
@import '../../node_modules/tachyons-custom/src/_floats.css';
@import '../../node_modules/tachyons-custom/src/_font-family.css';
@import '../../node_modules/tachyons-custom/src/_font-style.css';
@import '../../node_modules/tachyons-custom/src/_font-weight.css';
@import '../../node_modules/tachyons-custom/src/_forms.css';
@import '../../node_modules/tachyons-custom/src/_heights.css';
@import '../../node_modules/tachyons-custom/src/_letter-spacing.css';
@import '../../node_modules/tachyons-custom/src/_line-height.css';
@import '../../node_modules/tachyons-custom/src/_links.css';
@import '../../node_modules/tachyons-custom/src/_lists.css';
@import '../../node_modules/tachyons-custom/src/_max-widths.css';
@import '../../node_modules/tachyons-custom/src/_widths.css';
@import '../../node_modules/tachyons-custom/src/_overflow.css';
@import '../../node_modules/tachyons-custom/src/_position.css';
@import '../../node_modules/tachyons-custom/src/_opacity.css';
@import '../../node_modules/tachyons-custom/src/_rotations.css';
@import '../../node_modules/tachyons-custom/src/_skins.css';
@import '../../node_modules/tachyons-custom/src/_skins-pseudo.css';
@import '../../node_modules/tachyons-custom/src/_spacing.css';
@import '../../node_modules/tachyons-custom/src/_negative-margins.css';
@import '../../node_modules/tachyons-custom/src/_tables.css';
@import '../../node_modules/tachyons-custom/src/_text-decoration.css';
@import '../../node_modules/tachyons-custom/src/_text-align.css';
@import '../../node_modules/tachyons-custom/src/_text-transform.css';
@import '../../node_modules/tachyons-custom/src/_type-scale.css';
@import '../../node_modules/tachyons-custom/src/_typography.css';
@import '../../node_modules/tachyons-custom/src/_utilities.css';
@import '../../node_modules/tachyons-custom/src/_visibility.css';
@import '../../node_modules/tachyons-custom/src/_white-space.css';
@import '../../node_modules/tachyons-custom/src/_vertical-align.css';
@import '../../node_modules/tachyons-custom/src/_hovers.css';
@import '../../node_modules/tachyons-custom/src/_z-index.css';
@import '../../node_modules/tachyons-custom/src/_nested.css';
@import '../../node_modules/tachyons-custom/src/_styles.css';

/* Variables */
/* Importing here will allow you to override any variables in the modules */

@import '../../node_modules/tachyons-custom/src/_variables.css';
@import '../../src/themes/overrides.css';
@import '../../src/themes/visibility.css';

/* Uncomment out the lines below to help debug layout issues */
/*
@import '../../node_modules/tachyons-custom/src/_debug-children.css';
@import '../../node_modules/tachyons-custom/src/_debug-grid.css';
*/