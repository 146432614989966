/* Overrides of the classes from tachiyons*/
/*@custom-media --breakpoint-not-small screen and (min-width: 30em);*/
@custom-media --breakpoint-medium screen and (min-width: 30em) and (max-width: 1199px);
/* Used by classes with -l*/
@custom-media --breakpoint-large screen and (min-width: 1200px);

/* Commented out lines are variables which are not used in our design*/
/* Some colour variable are custom, but follow Tachyons pattern*/
:root {
  --orange: rgb(252, 78, 54);
  --orange-20: rgba(252, 78, 54, 0.2);
  --orange-10: rgba(252, 78, 54, 0.1);
  --yellow: rgb(255, 140, 49);
  --yellow-10: rgba(255, 140, 49, 0.1);
  --red: rgb(222, 18, 76);
  --red-10: rgba(222, 18, 76, 0.1);
  --green: rgb(22, 206, 109);
  --green-10: rgba(22, 206, 109, 0.1);
  --grey: rgb(147, 148, 152);
  --grey-40: rgba(147, 148, 152, 0.4);
  --grey-30: rgba(147, 148, 152, 0.3);
  --grey-20: rgba(147, 148, 152, 0.2);
  --grey-15: rgba(147, 148, 152, 0.15);
  --grey-10: rgba(147, 148, 152, 0.1);
  --grey-5: rgba(147, 148, 152, 0.05);
  --white: rgb(253, 253, 253);
  --white-ghost: rgb(248, 247, 247);
  --white-smoke: rgb(239, 237, 237);
  --white-stone: rgba(226, 226, 226, 0.9);
  --dark: rgba(39, 38, 41, 0.9);
  --dark-low: rgb(46, 45, 48);
  --dark-mid: rgb(53, 52, 56);
  --dark-high: rgb(61, 59, 64);
  --dark-lead: rgb(71, 69, 74);
  --black-98: rgba(31, 31, 33, 0.98);
  --black-60: rgba(31, 31, 33, 0.6);
  --black-40: rgba(31, 31, 33, 0.4);
  --black-35: rgba(31, 31, 33, 0.35);
  --black-25: rgba(31, 31, 33, 0.25);
  --black-20: rgba(31, 31, 33, 0.2);
  --black-5: rgba(31, 31, 33, 0.05);

  --brand-gradient: linear-gradient(116deg, #de124c 6%, rgb(252,78,54) 21%, rgb(252,78,54) 71%, #fd6934 79%, #fe7b32 84%);

  /* Colours that change based on light/dark theme */
  --brand: var(--orange);
  --brand-contrast: var(--white-ghost);
  --main-contrast: var(--white-ghost);
  --main-invert: var(--dark);
  --background-lowlight: var(--dark);
  --background-main: var(--dark-low);
  --background-secondary: var(--dark-mid);
  --background-highlight: var(--dark-high);
  --background-invert: var(--white-ghost);

  --cta-tertiary-border: 1px solid var(--grey-20);

  --font-family: "Helvetica Now", -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;


  /* Massive Heading fonts to be defined in the future*/
  /*--font-size-headline: 6rem;*/
  /*--font-size-subheadline: 5rem;*/
  --font-size-1: 38px;
  --font-size-2: 28px;
  --font-size-3: 18px;
  --font-size-4: 16px;
  --font-size-5: 14px;
  --font-size-6: 12px;
  --font-size-7: 10px;

  --height-1: 14px;
  --height-2: 20px;
  --height-3: 24px;
  --height-4: 34px;
  --height-5: 44px;
  /*Custom variables below*/
  --height-6: 54px;
  --height-7: 90px;
  /*--height-8: 44px;*/
  /*--height-9: 44px;*/

  --width-1: 14px;
  --width-2: 20px;
  --width-3: 24px;
  --width-4: 34px;
  --width-5: 44px;
  /*Custom variables below*/
  --width-6: 54px;
  --width-7: 90px;
  /*--width-8: 44px;*/
  /*--width-9: 44px;*/

  --max-width-1: 120px;
  --max-width-2: 280px;
  --max-width-3: 320px;
  --max-width-4: 435px;
  --max-width-5: 768px;
  /*--max-width-6: 0px;*/
  /*--max-width-7: 0px;*/
  /*--max-width-8: 0px;*/
  /*--max-width-9: 0px;*/

  /*--box-shadow-1: 0px 0px 4px 2px rgba( 0, 0, 0, 0.2 );*/
  --box-shadow-2: 0 1px 10px 0 rgba(0, 0, 0, 0.4);
  --box-shadow-3: 0 10px 30px 0 rgb(31,31,33, 0.2);
  /*--box-shadow-4: 2px 2px 8px 0px rgba( 0, 0, 0, 0.2 );*/
  /*--box-shadow-5: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 );*/

  --border-radius-none: 0;
  --border-radius-1: 1px;
  --border-radius-2: 2px;
  --border-radius-3: 4px;
  /*--border-radius-4: 5px;*/
  --border-radius-circle: 100%;
  --border-radius-pill: 9999px;

  /*--border-width-none: 0;*/
  --border-width-1: 1px;
  --border-width-2: 2px;
  /*--border-width-3: .5rem;*/
  /*--border-width-4: 1rem;*/
  /*--border-width-5: 2rem;*/

  /*--spacing-none: 0;*/
  /*--spacing-extra-small: 4px !important;*/
  --spacing-small: 5px;
  --spacing-medium: 10px;
  --spacing-large: 20px;
  --spacing-extra-large: 30px;
  --spacing-extra-extra-large: 80px;
  --spacing-extra-extra-extra-large: 120px;
}
